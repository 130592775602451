import { Link } from "components/link/Link.component";
import React from "react";
import linksService from "services/links.service";
import {
  PARAGRAPH_1,
  PARAGRAPH_2,
  PARAGRAPH_3,
  PARAGRAPH_4,
  PARAGRAPH_5_A,
  PARAGRAPH_5_B,
  WELCOME_TEXT,
} from "./SchoolBio.constants";

export const SchoolBio = () => {
  return (
    <>
      <h4>
        {WELCOME_TEXT}
        <span className="waving-hand-class">👋</span>
      </h4>
      <p>{PARAGRAPH_1}</p>
      <p>{PARAGRAPH_2}</p>
      <p>{PARAGRAPH_3}</p>
      <p>{PARAGRAPH_4}</p>
      <p>
        {PARAGRAPH_5_A}
        <Link
          className="animated-link"
          to={linksService.scheduleVisit()}
          newTab
        >
          <span>here</span>
        </Link>
        {PARAGRAPH_5_B}
      </p>
      <p>Sincerely,</p>
      <p>
        <p>Tanya Hernandez</p>
        <div>
          <em>Director of Admissions</em>
        </div>
        <div>
          <Link to={linksService.email()}>admissions@pacbay.org</Link>
        </div>
      </p>
    </>
  );
};
