import { AccordionContainer } from "components/accordion-container/AccordionContainer.component";
import { BlueGrouping } from "components/color-groupings/blue-grouping/BlueGrouping.component";
import { WhiteGrouping } from "components/color-groupings/white-grouping/WhiteGrouping.component";
import { QUESTION_MINIMIZE_BREAKPOINT, TOUR_VISIT } from "components/constants";
import { ImageHeading } from "components/page-specific-components/schools/components/image-heading/ImageHeading.component";
import { SchoolCard } from "components/school-card/SchoolCard.component";
import { SEO } from "components/seo/SEO.component";
import { StackedCallToAction } from "components/stacked-call-to-action/StackedCallToAction.component";
import { SchoolJourney } from "components/svg-wrapper/components/groups/SchoolJourney.svg";
import { SVGIcons } from "components/svg-wrapper/SvgWrapper.enum";
import { Video } from "components/video/Video.component";
import { motion, useAnimation } from "framer-motion";
import { graphql, useStaticQuery } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { useWindowWidth } from "hooks/windowWidth.hook";
import React, { useEffect, useRef } from "react";
import { useInView } from "react-intersection-observer";
import linksService from "services/links.service";
import "styles/animations/wavingHand.animation.scss";
import { getVideoJsOptions } from "./Admissions.config";
import { SchoolBio } from "./components/school-bio/SchoolBio.component";
import "./Admissions.styles.scss";

export const Admissions = () => {
  const playerRef = useRef(null);
  const controls = useAnimation();
  const collageControls = useAnimation();
  const [ref, inView] = useInView({
    threshold: 0.5,
  });
  const [collageRef, collageInView] = useInView({
    threshold: 0.25,
  });

  const windowWidth = useWindowWidth();

  const {
    allContentfulFaq: { edges: admissionsFaqs },
    allContentfulVideo: {
      edges: {
        0: { node: virtualTour },
      },
    },
  } = useStaticQuery(graphql`
    query AdmissionsFAQsQueryAndVideoTourQuery {
      allContentfulFaq(
        filter: { enabled: { eq: true }, type: { eq: "admissions" } }
        sort: { fields: createdAt, order: ASC }
      ) {
        edges {
          node {
            id
            question
            answer {
              raw
            }
          }
        }
      }
      allContentfulVideo(
        limit: 1
        filter: { id: { eq: "d2578389-52c9-502d-bae7-09773d0037ef" } }
      ) {
        edges {
          node {
            id
            title
            videoUpload {
              id
              uploadId
              assetId
              playbackId
              ready
              ratio
            }
          }
        }
      }
    }
  `);

  const handlePlayerReady = (player) => {
    playerRef.current = player;
    // you can handle player events here
    player.on("waiting", () => {
      console.log("player is waiting");
    });

    player.on("dispose", () => {
      console.log("player will dispose");
    });
  };

  useEffect(() => {
    if (inView) {
      controls.start((i) => ({
        transform: `translateX(${i[0]}) rotate(${i[1]})`,
        transition: { duration: 1 },
      }));
    }
  }, [controls, inView]);

  useEffect(() => {
    if (collageInView) {
      collageControls.start((i) => ({
        transform: `translate(${i.translate[0]}, ${i.translate[1]}) rotate(${i.rotate})`,
        opacity: 1,
        top: "0%",
      }));
    }
  }, [collageControls, collageInView]);

  const isMobile = windowWidth <= QUESTION_MINIMIZE_BREAKPOINT;

  return (
    <>
      <SEO title="Admissions" />
      <main>
        <BlueGrouping>
          <section
            className="fix-header-overlap"
            style={{
              paddingBottom: "10rem",
            }}
          >
            <ImageHeading heading="Admissions">
              <StaticImage
                src="../../images/jpg/admissions.jpg"
                alt="two kids smiling and looking at each other"
                placeholder="blurred"
                layout="constrained"
                className="admissions-header-image-margining"
              />
            </ImageHeading>
            <div className="layout">
              <div className="admissions-wrapper">
                <div className="admissions-bio">
                  <SchoolJourney
                    className="school-journey"
                    width="7.5rem"
                    opacity={0.8}
                  />
                  <SchoolBio />
                </div>
                <div ref={ref} className="admissions-slide-in-image-wrapper">
                  <motion.div
                    initial={{
                      transform: "translateX(200%) rotate(50deg)",
                    }}
                    custom={["0%", "10deg"]}
                    animate={controls}
                    style={{ marginBottom: "3rem", willChange: "transform" }}
                  >
                    <StaticImage
                      style={{ borderRadius: "0.83rem" }}
                      src="../../images/jpg/painting.jpg"
                      alt=""
                      placeholder="blurred"
                      layout="constrained"
                    />
                  </motion.div>
                  <motion.div
                    initial={{
                      transform: "translateX(200%) rotate(50deg)",
                    }}
                    custom={["10%", "-10deg"]}
                    animate={controls}
                    style={{ willChange: "transform" }}
                  >
                    <StaticImage
                      style={{ borderRadius: "0.83rem" }}
                      src="../../images/jpg/surfing_closeup.jpg"
                      alt=""
                      placeholder="blurred"
                      layout="constrained"
                    />
                  </motion.div>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Video
                  className="virtual-tour-video"
                  style={{
                    "--vjs-theme-forest--primary": "var(--primary)",
                    "--vjs-theme-forest--secondary": "var(--white)",
                    borderRadius: "1rem",
                    boxShadow: "var(--level-2)",
                  }}
                  options={getVideoJsOptions(virtualTour)}
                  onReady={handlePlayerReady}
                  bubbleText="Click below for a virtual campus tour!"
                />
              </div>
            </div>
          </section>
          <StackedCallToAction
            headerText={
              isMobile ? TOUR_VISIT.HEADER.MOBILE : TOUR_VISIT.HEADER.DESKTOP
            }
            paragraphText={
              isMobile
                ? TOUR_VISIT.PARAGRAPH.MOBILE
                : TOUR_VISIT.PARAGRAPH.DESKTOP
            }
            buttonObj={{
              label: TOUR_VISIT.BUTTON_LABEL.DESKTOP,
              icon: SVGIcons.Visit,
              link: linksService.scheduleVisit(),
              newTab: true,
            }}
          />
        </BlueGrouping>
        <WhiteGrouping>
          <section
            className="layout"
            style={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              paddingTop: "3rem",
              paddingBottom: "6rem",
            }}
          >
            <h3>Admissions Process</h3>
            <section className="admissions-cards-wrapper">
              <SchoolCard
                link={linksService.howToApply()}
                label="How To Apply"
                hoverText="Deadlines, Requirements"
              >
                <StaticImage
                  src="../../images/jpg/how_to_apply.jpg"
                  alt=""
                  placeholder="blurred"
                  layout="constrained"
                />
              </SchoolCard>
              <SchoolCard
                link={linksService.affordingPacbay()}
                label="Affording PacBay"
                hoverText="FAQs, Tuition, Assistance"
              >
                <StaticImage
                  src="../../images/jpg/affording_pacbay.jpg"
                  alt=""
                  placeholder="blurred"
                  layout="constrained"
                />
              </SchoolCard>
            </section>
            <AccordionContainer title="Admissions FAQs" faqs={admissionsFaqs} />
          </section>
        </WhiteGrouping>
      </main>
    </>
  );
};

export default Admissions;
