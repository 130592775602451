import { RELATED_PAGES_LABELS } from "components/constants";
import { SEO } from "components/seo/SEO.component";
import { useRelatedPages } from "hooks/relatedPages.hook";
import React from "react";
import linksService from "services/links.service";
import "styles/animations/wavingHand.animation.scss";
import { Admissions as AdmissionsModule } from "modules/admissions/Admissions.component";

const Admissions = () => {
  useRelatedPages([
    {
      label: RELATED_PAGES_LABELS.HOW_TO_APPLY,
      link: linksService.howToApply(),
    },
    {
      label: RELATED_PAGES_LABELS.AFFORDING_PACBAY,
      link: linksService.affordingPacbay(),
    },
  ]);

  return (
    <>
      <SEO title="Admissions" />
      <AdmissionsModule />
    </>
  );
};

export default Admissions;
