export const WELCOME_TEXT = "Hello from the Admissions Office!";

export const PARAGRAPH_1 = `Welcome to Pacific Bay Christian School! We are a Christ-centered community in the San Francisco Bay Area dedicated to inspiring students to become ambitious, joyful, and deeply engaged leaders in an ever-changing world.`;
export const PARAGRAPH_2 = `At PacBay, our vision is clear: to nurture spiritual curiosity, a love of learning, and a deep engagement for meaningful work in every student. These core values form a strong foundation for our student success. Our faculty and staff are committed to truly knowing each student, understanding their individual interests, and offering a wide range of resources to help them achieve their educational goals. At PacBay, every student is known, valued and supported.`;
export const PARAGRAPH_3 = `We are proud to be a diverse, inclusive, and ecumenical community. With the feel of a small college, students have access to over 180 UC-approved courses (as we continue to add more every year), five world languages, and our distinctive Major Distinction Program. PacBay’s small class sizes foster a close-knit community with individualized attention and a supportive environment. Our student body reflects a wide range of ethnic, cultural, and socioeconomic backgrounds, creating a culturally rich and dynamic learning atmosphere.`;
export const PARAGRAPH_4 = `At PacBay, we often say, "Once you’re part of the PacBay community, you’re always part of the PacBay community"—wherever life may lead. Our students excel in academics, athletics, and the arts, and go on to pursue exciting opportunities worldwide. PacBay alumni frequently return to our school to stay connected and continue benefiting from our resources as they embark on their future endeavors.`;
export const PARAGRAPH_5_A = `We invite you to visit our campus to experience our vibrant, welcoming community. Schedule a tour today `;
export const PARAGRAPH_5_B = ` or email me directly below to learn more about our admissions process. We look forward to meeting you soon and welcoming your family to the PacBay community.`;

// export const PARAGRAPH_1 = `Welcome to Pacific Bay Christian School, "PacBay," a Christ-centered community of scholarship and discipleship. I'm so glad you've stopped by today.`;
// export const PARAGRAPH_2 = `Everything at PacBay is driven by a common goal: nurturing our students' growth in faith, knowledge, and virtue. As a staff, we love getting to know the students, learning about their interests, and introducing them to all we have to offer in support of their educational goals. At Pacific Bay Christian School, every student is known, and every student is seen.`;
// export const PARAGRAPH_3 = `We are a diverse, ecumenical, and inclusive community. We have a small college feeling as students are offered over 110 course options, 6 world languages, and are invited to participate in a unique Major Distinction Program. This way, no matter their interests, students will be given the opportunity to grow into their fullest potential. Our students come from more than 7 Bay Area Counties, and several students also participate in our virtual learning program, joining the PacBay community from all over the world, including China, Myanmar, and Korea.`;
// export const PARAGRAPH_4 = `At PacBay, we often say, once you are a part of the PacBay community, you are always a part of the PacBay community-no matter where life may take you. Our students are both ambitious and joyful, which leads them to pursue incredible endeavors all over the world.`;
// export const PARAGRAPH_5_A = `From all of us at PacBay, we are delighted to introduce you to our school. Our doors are open for your visit every school day. Please contact us `;
// export const PARAGRAPH_5_B = ` to schedule a tour! I look forward to meeting you soon!`;
