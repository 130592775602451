import PacBayLogo from "images/png/PacBayLogo_School.png";

export const getVideoJsOptions = (virtualTour) => ({
  width: "800rem",
  controls: true,
  preload: "auto",
  sources: [
    {
      src: `https://stream.mux.com/${virtualTour.videoUpload.playbackId}.m3u8`,
      type: "application/x-mpegURL",
    },
  ],
  poster: PacBayLogo,
});
