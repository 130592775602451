import { Link } from "components/link/Link.component";
import { motion } from "framer-motion";
import React from "react";
import "./SchoolCard.styles.scss";

export const SchoolCard = ({ label, link, hoverText, children }) => {
  return (
    <Link to={link}>
      <motion.div
        className="image-wrapper"
        whileHover={{
          scale: 1.04,
          rotateZ: "0.75deg",
          transition: { duration: 0.2 },
        }}
      >
        {children}
        <div className="footer-label">
          <p className="label-text">{label}</p>
          <motion.p className="hover-text">{hoverText}</motion.p>
        </div>
      </motion.div>
    </Link>
  );
};
