import React, { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import videojs from "video.js";
import "video.js/dist/video-js.css";
import { BubbleText } from "./components/bubble-text/BubbleText.component";

export const Video = ({ options, onReady, className, style, bubbleText }) => {
  const videoRef = useRef(null);
  const playerRef = useRef(null);
  const [isPlayerReady, toggleIsPlayerReady] = useState(false);

  useEffect(() => {
    // make sure Video.js player is only initialized once
    if (!playerRef.current) {
      const videoElement = videoRef.current;
      if (!videoElement) return;

      const player = (playerRef.current = videojs(videoElement, options, () => {
        toggleIsPlayerReady(true);
        onReady && onReady(player);
      }));
    } else {
      // you can update player here [update player through props]
      // const player = playerRef.current;
      // player.autoplay(options.autoplay);
      // player.src(options.sources);
    }
  }, [options, videoRef]);

  // Dispose the Video.js player when the functional component unmounts
  useEffect(() => {
    const player = playerRef.current;

    return () => {
      if (player) {
        player.dispose();
        playerRef.current = null;
      }
    };
  }, [playerRef]);

  return (
    <div
      data-vjs-player
      style={{
        position: "relative",
        display: "flex",
        justifyContent: "center",
      }}
    >
      {bubbleText && (
        <BubbleText text={bubbleText} isPlayerReady={isPlayerReady} />
      )}
      <Helmet>
        <link
          href="https://unpkg.com/@videojs/themes@1/dist/forest/index.css"
          rel="stylesheet"
        />
      </Helmet>
      <video
        ref={videoRef}
        className={`${className} video-js vjs-big-play-centered vjs-theme-forest`}
        style={style}
      />
    </div>
  );
};

export default Video;
