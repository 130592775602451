import { AnimatePresence, motion } from "framer-motion";
import React from "react";
import "./BubbleText.styles.scss";

export const BubbleText = ({ text, isPlayerReady }) => {
  return (
    <AnimatePresence>
      {isPlayerReady && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.6 }}
          className="bubble-text"
        >
          {text}
        </motion.div>
      )}
    </AnimatePresence>
  );
};
